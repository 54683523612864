<script setup lang="ts">
import { hydrateOnVisible } from 'vue';
import type { SlotType } from '@/types';

const CiCampsiteList = defineAsyncComponent({
  loader: () => import('@/components/slots/modules/CiCampsiteList/CiCampsiteList.vue'),
  hydrate: hydrateOnVisible(),
});
const CiFAQAccordion = defineAsyncComponent({
  loader: () => import('@/components/slots/modules/CiFAQAccordion/CiFAQAccordion.vue'),
  hydrate: hydrateOnVisible(),
});
const CiImage = defineAsyncComponent({
  loader: () => import('@/components/slots/modules/CiImage/CiImage.vue'),
  hydrate: hydrateOnVisible(),
});
const CiImageText = defineAsyncComponent({
  loader: () => import('@/components/slots/modules/CiImageText/CiImageText.vue'),
  hydrate: hydrateOnVisible(),
});
const CiLocationList = defineAsyncComponent({
  loader: () => import('@/components/slots/modules/CiLocationList/CiLocationList.vue'),
  hydrate: hydrateOnVisible(),
});
const CiRichText = defineAsyncComponent({
  loader: () => import('@/components/slots/modules/CiRichText/CiRichText.vue'),
  hydrate: hydrateOnVisible(),
});
const CiTeaser = defineAsyncComponent({
  loader: () => import('@/components/slots/modules/CiTeaser/CiTeaser.vue'),
  hydrate: hydrateOnVisible(),
});
const CiTwoBanners = defineAsyncComponent({
  loader: () => import('@/components/slots/modules/CiTwoBanners/CiTwoBanners.vue'),
  hydrate: hydrateOnVisible(),
});
const CiVideo = defineAsyncComponent({ loader: () => import('@/components/slots/modules/CiVideo/CiVideo.vue'),
  hydrate: hydrateOnVisible(),
});

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiSlotContentProps {
  slug?: SlotType;
  extra?: any;
}
const props = withDefaults(defineProps<CiSlotContentProps>(), {
  slug: 'content',
  extra: {},
});

/********************
 * COMPOSITIONS      *
 ********************/
const contentStore = useContentStore();

/********************
 * FUNCTIONS         *
 ********************/
function determineComponent(content: any, index: number) {
  if (content.content_type === 'fourteasers') {
    return {
      component: CiTeaser,
      attrs: {
        contentType: content.content_type,
        fields: content.fields,
        index,
        language: content.translations_lang || null,
        slug: props.slug,
      },
      events: {},
    };
  } else if (content.content_type === 'markdown') {
    return {
      component: CiRichText,
      attrs: {
        extra: { ...props.extra, slug: props.slug },
        fields: content.fields,
        language: content.translations_lang || null,
      },
      events: {},
    };
  } else if (content.content_type === 'twobanners') {
    return {
      component: CiTwoBanners,
      attrs: {
        contentType: content.content_type,
        fields: content.fields,
        index,
        language: content.translations_lang || null,
        slug: props.slug,
      },
      events: {},
    };
  } else if (content.content_type === 'imagetext') {
    return {
      component: CiImageText,
      attrs: {
        contentType: content.content_type,
        fields: content.fields,
        index,
        language: content.translations_lang || null,
        slug: props.slug,
      },
      events: {},
    };
  } else if (content.content_type === 'faqaccordion') {
    return {
      component: CiFAQAccordion,
      attrs: {
        fields: content.fields,
        language: content.translations_lang || null,
      },
      events: {},
    };
  } else if (content.content_type === 'fullwidthimage') {
    return {
      component: CiImage,
      attrs: {
        contentType: content.content_type,
        fields: content.fields,
        index,
        language: content.translations_lang || null,
        slug: props.slug,
      },
      events: {},
    };
  } else if (content.content_type === 'video') {
    return {
      component: CiVideo,
      attrs: {
        contentType: content.content_type,
        fields: content.fields,
        index,
        language: content.translations_lang || null,
        slug: props.slug,
      },
      events: {},
    };
  } else if (content.content_type === 'campsitelist' || content.content_type === 'manualcampsitelist') {
    return {
      component: CiCampsiteList,
      attrs: {
        contentType: content.content_type,
        fields: content.fields,
        index,
        language: content.translations_lang || null,
        slug: props.slug,
      },
      events: {},
    };
  } else if (content.content_type === 'locationlist') {
    return {
      component: CiLocationList,
      attrs: {
        contentType: content.content_type,
        fields: content.fields,
        index,
        language: content.translations_lang || null,
        slug: props.slug,
      },
      events: {},
    };
  } else {
    return {
      component: false,
    };
  }
}
</script>

<template>
  <div
    v-if="contentStore.getContentsForSlot(props.slug).length > 0"
    class="section section--slot-content relative"
  >
    <template
      v-for="(content, index) in contentStore.getContentsForSlot(props.slug)"
      :key="`${index}-${content.content_type}`"
    >
      <component
        :is="determineComponent(content, index)?.component"
        v-bind="determineComponent(content, index).attrs"
        v-on="determineComponent(content, index).events || {}"
      />
    </template>
  </div>
</template>
